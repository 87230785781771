/* eslint-disable @typescript-eslint/no-explicit-any */
// import 'date-fns';
import { Box, TextFieldProps, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ReportProblemOutlined as Error } from '@material-ui/icons';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import { Controller } from 'react-hook-form';

type Props = TextFieldProps & {
  control: any;
  name: string;
  label?: string;
  labelTime?: string;
  error?: any;
  style?: React.CSSProperties;
  variant?: 'outlined' | 'filled' | 'standard';
  size?: 'medium' | 'small';
  disabled?: boolean;
  required?: boolean;
  disableFuture?: boolean;
};

export const InputDatePicker: React.FC<Props> = ({
  control,
  name,
  label,
  labelTime,
  error,
  style,
  size,
  variant = 'outlined',
  disabled = false,
  required = false,
  disableFuture = false,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ marginBottom: 15, ...style }}
    >
      <Grid container justifyContent="flex-start">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <KeyboardDatePicker
                inputVariant={variant}
                id={`${label}-date-picker-dialog`}
                label={label}
                format="DD/MM/YYYY"
                value={field.value || null}
                onChange={field.onChange}
                error={!!error[name]?.message}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disableFuture={disableFuture}
                disabled={disabled}
                required={required}
                size={size}
                style={{ flex: 1 }}
              />
              {labelTime && (
                <KeyboardTimePicker
                  inputVariant={variant}
                  id={`${labelTime}-time-picker`}
                  label={labelTime || 'Hora'}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!error[name]?.message}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  disabled={disabled}
                  size={size}
                  style={{ flex: 1, marginLeft: 10 }}
                />
              )}
            </>
          )}
        />
      </Grid>

      {name && error[name]?.message && (
        <Typography
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
};
